<template>
  <b-card class="user-services">
    <b-modal
      id="create-dispute-modal"
      v-model="serviceHistoryProps.viewDisputeModal"
      :class="disputeObject ? 'height-auto' : null"
      :no-close-on-backdrop="true"
      :title="$t('Dispute Details')"
      :ok-title="$t('Submit')"
      ok-only
      @hidden="hideDisputeModal"
      @ok="submitServiceDispute"
    >
      <b-col v-if="!disputeObject" cols="12" md="12">
        <b-form-group :label="$t('Dispute Name')" class="price-range-dropdown">
          <v-select v-model="serviceHistoryProps.currentDispute" :dir="$store.state.appConfig.isRTL ? 'rtl' : 'ltr'" :options="disputeOptions" :reduce="val => val.value" :clearable="false" />
        </b-form-group>
        <b-form-group v-if="serviceHistoryProps.currentDispute === 'Others'" :label="$t('Dispute Reason')" label-for="disputeReason">
          <b-form-input id="disputeReason" v-model="serviceHistoryProps.disputeReason" type="text" />
        </b-form-group>
      </b-col>
      <b-col v-if="disputeObject" cols="12" md="12">
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Dispute Name') }}:</span> {{ serviceHistoryProps.disputeObject.dispute_name }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Date') }}:</span> {{ serviceHistoryProps.disputeObject.created_time }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Status') }}:</span> {{ serviceHistoryProps.disputeObject.status }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Comments By') }}:</span> {{ serviceHistoryProps.disputeObject.comments_by }}
        </p>
        <p class="mb-50">
          <span class="font-weight-bold">{{ $t('Comments') }}:</span> {{ serviceHistoryProps.disputeObject.comments }}
        </p>
      </b-col>
    </b-modal>
    <b-modal id="acceptRequest" :no-close-on-backdrop="true" :title="$t('Approve Request')" :ok-title="$t('Submit')" ok-only @ok="submitApprovalRequest">
      <b-form-group :label="$t('Comment')">
        <b-form-input id="disputeReason" v-model="serviceHistoryProps.acceptRequestMessage" type="text" />
      </b-form-group>
    </b-modal>
    <b-modal
      id="upcomingServiceModal"
      v-model="serviceHistoryProps.upcomingServiceDetailVisible"
      :no-close-on-backdrop="true"
      :title="$t('Service Details')"
      :hide-footer="true"
      size="lg"
      @hidden="hideUpcomingServiceModal"
    >
      <b-row v-if="serviceHistoryProps.tripHistoryObject">
        <b-col md="6" class="border p-1">
          <p class="py-2 text-center font-weight-bold font-18 text-white" style="background-color: #7367f0">
            {{ serviceHistoryProps.tripHistoryObject.booking_id }}
          </p>
          <div class="row">
            <div :class="canReschedule ? 'col-md-8' : 'col-md-12'">
              <p class="mb-50">
                <span class="font-weight-bold">{{ $t('Service Type') }}: </span>{{ getServiceType }}
              </p>
              <p class="mb-50">
                <span class="font-weight-bold">{{ $t('Service Location') }}: </span>{{ serviceHistoryProps.tripHistoryObject.s_address }}
              </p>
              <p class="mb-50">
                <span class="font-weight-bold">{{ $t('Payment Mode') }}: </span>{{ serviceHistoryProps.tripHistoryObject.payment_mode }}
              </p>
              <p v-if="serviceHistoryProps.tripHistoryObject.created_time" class="mb-50">
                <span class="font-weight-bold">{{ $t('Request Date') }}: </span>{{ serviceHistoryProps.tripHistoryObject.created_time }}
              </p>
              <p v-if="serviceHistoryProps.tripHistoryObject.schedule_at" class="mb-50">
                <span class="font-weight-bold">{{ $t('Schedule Date') }}: </span>{{ serviceHistoryProps.tripHistoryObject.schedule_at }}
              </p>
            </div>
            <div v-if="canReschedule" class="col-md-4 text-center">
              <button class="btn btn-primary" @click="rescheduleRequest">
                {{ $t('Reschedule') }}
              </button>
            </div>
          </div>
          <hr />
          <p>{{ $t('Services Overview') }}</p>
          <table class="table table-striped">
            <tbody>
              <tr>
                <td>{{ $t('Booking Id') }}</td>
                <td>{{ serviceHistoryProps.tripHistoryObject.booking_id }}</td>
              </tr>
              <tr>
                <td>{{ $t('Total Minutes') }}</td>
                <td>{{ serviceHistoryProps.tripHistoryObject.distance }}</td>
              </tr>
              <tr v-if="serviceHistoryProps.tripHistoryObject.payment">
                <td>{{ $t('Base Fare') }}</td>
                <td>{{ serviceHistoryProps.tripHistoryObject.payment.fixed }} {{ currencyInfo }}</td>
              </tr>
              <tr v-if="serviceHistoryProps.tripHistoryObject.payment">
                <td>{{ $t('Tax Fare') }}</td>
                <td>{{ serviceHistoryProps.tripHistoryObject.payment.tax }} {{ currencyInfo }}</td>
              </tr>
              <tr v-if="serviceHistoryProps.tripHistoryObject.payment">
                <td>{{ $t('Extra Charges') }}</td>
                <td>{{ serviceHistoryProps.tripHistoryObject.payment.extra_charges }} {{ currencyInfo }}</td>
              </tr>
              <tr v-if="serviceHistoryProps.tripHistoryObject.payment">
                <td>{{ $t('Tips') }}</td>
                <td>{{ serviceHistoryProps.tripHistoryObject.payment.tips }} {{ currencyInfo }}</td>
              </tr>
              <tr v-if="serviceHistoryProps.tripHistoryObject.payment">
                <td>{{ $t('Wallet Detection') }}</td>
                <td>{{ serviceHistoryProps.tripHistoryObject.payment.wallet }} {{ currencyInfo }}</td>
              </tr>
              <tr v-if="serviceHistoryProps.tripHistoryObject.payment">
                <td>{{ $t('Total') }}</td>
                <td>{{ serviceHistoryProps.tripHistoryObject.payment.total }} {{ currencyInfo }}</td>
              </tr>
            </tbody>
          </table>
          <hr />
          <p v-if="serviceHistoryProps.tripHistoryObject.status === 'WAITING_FOR_USER_APPROVAL'" class="mb-1 text-center">
            {{ $t('Provider has updated the service for') }} {{ serviceHistoryProps.tripHistoryObject.schedule_at }}
          </p>
          <div v-if="serviceHistoryProps.tripHistoryObject.status !== 'CANCELLED'" class="d-flex justify-content-center">
            <b-button v-if="serviceHistoryProps.tripHistoryObject.status === 'WAITING_FOR_USER_APPROVAL'" @click="rescheduleService('ACCEPTED')" variant="success" class="mx-1">
              {{ $t('Accept') }}
            </b-button>
            <b-button v-if="serviceHistoryProps.tripHistoryObject.status === 'WAITING_FOR_USER_APPROVAL'" variant="danger" @click="cancelServiceRequest" class="mx-1">
              {{ $t('Reject') }}
            </b-button>
          </div>
          <!-- <div v-if="serviceHistoryProps.tripHistoryObject.status === 'CANCELLED'" class="d-flex justify-content-center">
            <b-button variant="danger">
              {{ $t('Cancelled') }}
            </b-button>
          </div> -->
        </b-col>
        <b-col v-if="serviceHistoryProps.tripHistoryObject.provider" md="6" class="border p-1 border-left-0">
          <div class="row mb-2">
            <div class="col-md-6" style="height: 150px">
              <p class="text-center mb-0">
                {{ $t('Before') }}
              </p>
              <img
                :src="
                  serviceHistoryProps.tripHistoryObject.before_image ? $helpers.imageHelper(serviceHistoryProps.tripHistoryObject.before_image) : require('@/assets/images/app-images/choose_image.svg')
                "
                class="w-100 h-100"
                style="height: 120px !important; object-fit: contain"
                @error="$helpers.imageError"
              />
            </div>
            <div class="col-md-6" style="height: 150px">
              <p class="text-center mb-0">
                {{ $t('After') }}
              </p>
              <img
                :src="
                  serviceHistoryProps.tripHistoryObject.after_image ? $helpers.imageHelper(serviceHistoryProps.tripHistoryObject.after_image) : require('@/assets/images/app-images/choose_image.svg')
                "
                class="w-100 h-100"
                style="height: 120px !important; object-fit: contain"
                @error="$helpers.imageError"
              />
            </div>
          </div>
          <div class="row">
            <div class="col-md-8">
              <b-media>
                <template #aside>
                  <b-avatar :src="$helpers.imageHelper(serviceHistoryProps.tripHistoryObject.provider.picture)" />
                </template>
                <h6>{{ serviceHistoryProps.tripHistoryObject.provider.first_name }} {{ serviceHistoryProps.tripHistoryObject.provider.last_name }}</h6>
                <div class="mb-0">
                  <ul class="unstyled-list list-inline">
                    <li v-for="star in 5" :key="star" class="ratings-list-item" :class="{ 'ml-25': star - 1 }">
                      <feather-icon
                        icon="StarIcon"
                        size="16"
                        :class="[
                          { 'fill-current': star <= serviceHistoryProps.tripHistoryObject.provider.rating },
                          star <= serviceHistoryProps.tripHistoryObject.provider.rating ? 'text-warning' : 'text-muted',
                        ]"
                      />
                    </li>
                  </ul>
                </div>
              </b-media>
            </div>
            <div class="col-md-4">
              <h3>{{ $t('Service OTP') }}: {{ serviceHistoryProps.tripHistoryObject.otp }}</h3>
            </div>
          </div>
          <hr />
          <p>{{ $t('Services Requested') }}</p>
          <b-media v-for="(cart, index) of cartDetails" :key="index" class="pb-50 pt-50" :class="{ 'border-bottom': index < cartDetails.length - 1 }">
            <template #aside>
              <b-avatar :src="$helpers.imageHelper(cart.provider_service.main_service.picture)" />
            </template>
            <h6>{{ cart.provider_service.main_service.service_name }}</h6>
            <p class="mb-0">
              <span class="font-weight-bold">{{ $t('Price') }} &amp; {{ $t('Qty') }}: </span>{{ cart.provider_service.base_fare }} {{ cart.provider.currency_symbol }} x {{ cart.quantity }}
            </p>
            <p class="mb-0"><span class="font-weight-bold">Total: </span>{{ cart.total_service_price }} {{ cart.provider.currency_symbol }}</p>
          </b-media>
          <b-card v-if="serviceHistoryProps.tripHistoryObject && serviceHistoryProps.tripHistoryObject.history && serviceHistoryProps.tripHistoryObject.history.length > 0" class="mt-1">
            <h4 class="mb-1 pl-0 font-weight-bold">
              <b>{{ $t('History') }}</b>
            </h4>
            <app-timeline>
              <app-timeline-item
                v-for="(item, index) of serviceHistoryProps.tripHistoryObject.history"
                :key="index"
                :title="item.status + ' at ' + item.created_at"
                :variant="index == serviceHistoryProps.tripHistoryObject.history.length - 1 ? 'secondary' : 'primary'"
              />
            </app-timeline>
          </b-card>
        </b-col>
      </b-row>
    </b-modal>
    <b-modal
      id="updateRequestModal"
      v-model="viewUpdateRequestModal"
      :no-close-on-backdrop="true"
      :title="$t('Update Request')"
      @hidden="hideUpdateRequestModal"
      @ok="updateRequestStatus"
    >
      <div class="wrap-update-request">
        <div class="col-md-12">
          <b-form-group
            :label="$t('Schedule time')"
          >
            <custom-calender
              ref="calenderRef"
              :hide-submit="true"
              :timing="storeCurrentTimings"
              :all-timings="providerTimings"
              :store-slot-info="providerTimings"
            />
          </b-form-group>
        </div>
      </div>
    </b-modal>
    <b-row class="show-tabs-list-mobile">
      <b-col cols="12">
        <b-tabs pills class="nav-tabs-center">
          <b-tab active>
            <template #title>
              <span class="d-none d-sm-inline">{{ $t('Upcoming Services') }}</span>
            </template>
            <good-data-table
              ref="upcomingServiceRef"
              :columns="upcomingTripColumn"
              :total-column="'response.responseData.service.total'"
              :data-column="'response.responseData.service.data'"
              :api-endpoint="'/user/trips-history/service'"
              :extra-params="'type=upcoming'"
              :default-sort-params="{
                field: 'created_time',
                type: 'desc',
              }"
            />
          </b-tab>
          <b-tab>
            <template #title>
              <span class="d-none d-sm-inline">{{ $t('Services History') }}</span>
            </template>
            <good-data-table
              ref="serviceHistoryRef"
              :columns="myTripColumns"
              :total-column="'response.responseData.service.total'"
              :data-column="'response.responseData.service.data'"
              :api-endpoint="'/user/trips-history/service'"
              :extra-params="'type=history'"
              :default-sort-params="{
                field: 'created_time',
                type: 'desc',
              }"
            />
          </b-tab>
        </b-tabs>
      </b-col>
    </b-row>
  </b-card>
</template>

<script>
import { BRow, BMedia, BAvatar, BCol, BTab, BTabs, BCard, BModal, BFormGroup, BFormInput, BButton } from 'bootstrap-vue'
import GoodDataTable from '@/@core/layouts/shared/GoodDataTable.vue'
import vSelect from 'vue-select'
import moment from 'moment'
import AppTimeline from '@core/components/app-timeline/AppTimeline.vue'
import AppTimelineItem from '@core/components/app-timeline/AppTimelineItem.vue'
import { hideLoader, showDangerNotification, showErrorNotification, showLoader, showSuccessNotification } from '@/@core/comp-functions/ui/app'
import { useUserUi } from './useUser'
import eventBus from '@/libs/event-bus'
import CustomCalender from './shared/CustomCalender.vue'
import { useProviderUi } from '../provider/useProvider'

export default {
  components: {
    BRow,
    BCol,
    GoodDataTable,
    BTab,
    AppTimeline,
    AppTimelineItem,
    BTabs,
    BCard,
    BModal,
    BFormGroup,
    BFormInput,
    vSelect,
    BButton,
    BMedia,
    BAvatar,
    CustomCalender,
  },
  data() {
    return {
      providerTimings: [],
      storeCurrentTimings: [],
      serviceHistoryProps: {
        viewDisputeModal: false,
        currentDispute: '',
        disputeReason: '',
        currentServiceForDispute: {},
        disputeObject: {},
        tripHistoryObject: {},
        upcomingServiceDetailVisible: false,
        historyerviceDetailVisible: false,
        acceptRequestMessage: '',
      },
      viewUpdateRequestModal: false,
      disputeOptions: [
        {
          label: 'Select',
          value: '',
        },
        {
          label: 'Provider asked extra amount',
          value: 'Provider asked extra amount',
        },
        {
          label: 'My wallet amount does not deducted',
          value: 'My wallet amount does not deducted',
        },
        {
          label: 'Promocode amount does not reduced',
          value: 'Promocode amount does not reduced',
        },
        {
          label: 'Provider incompleted the service',
          value: 'Provider incompleted the service',
        },
        {
          label: 'Others',
          value: 'Others',
        },
      ],
      myTripColumns: [
        {
          label: 'Booking Id',
          field: 'booking_id',
        },
        {
          label: 'Date',
          field: 'created_time',
        },
        {
          label: 'Name',
          field: 'first_name',
          resolvePath: 'provider.first_name',
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if (props.provider) {
              return `${props.provider.first_name} ${props.provider.last_name}`
            }
            return 'NA'
          },
        },
        {
          label: 'Total Amount',
          field: 'total',
          rField: 'total_text',
          useResolver: true,
          resolvePath: 'payment.total',
          useRenderer: true,
          renderer: props => {
            if (props.payment) {
              return `${props.user.currency_symbol} ${props.payment.total}`
            }
            return 'NA'
          },
        },
        {
          label: 'Service Type',
          field: 'service.service_name',
        },
        {
          label: 'Payment',
          field: 'payment_payment_mode',
          resolvePath: 'payment.payment_mode',
          useResolver: true,
          useRenderer: true,
          renderer: props => {
            if (props.payment) {
              return props.payment.payment_mode
            }
            return 'NA'
          },
        },
        {
          label: 'Status',
          field: 'status',
          align: 'center',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('view'),
              text: 'View',
              action: props => {
                this.viewServiceHistory(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('tracking'),
              text: 'View Detail',
              action: props => {
                this.$router.push({
                  path: `/user/service/request/${props.id}`,
                })
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('dispute'),
              text: 'Dispute',
              action: props => {
                this.createServiceDispute(props)
              },
            },
          ],
        },
      ],
      upcomingTripColumn: [
        {
          label: 'Booking Id',
          field: 'booking_id',
        },
        {
          label: 'Date',
          field: 'created_time',
        },
        {
          label: 'Name',
          field: 'service.service_name',
          useRenderer: true,
          renderer: props => {
            if (props.provider) {
              return `${props.provider.first_name} ${props.provider.last_name}`
            }
            return 'NA'
          },
        },
        {
          label: 'Total Amount',
          field: 'provider_id',
          useRenderer: true,
          renderer: props => {
            if (props.payment) {
              return `${props.currency} ${props.payment.total}`
            }
            return 'NA'
          },
        },
        {
          label: 'Service Type',
          field: 'service.service_name',
        },
        {
          label: 'Payment',
          field: 'provider_id',
          useRenderer: true,
          renderer: props => {
            if (props.payment) {
              return props.payment.payment_mode
            }
            return 'NA'
          },
        },
        {
          label: 'Status',
          field: 'status',
          align: 'center',
        },
        {
          label: 'Action',
          field: 'action',
          sortable: false,
          type: 'dropdown',
          actions: [
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('view'),
              text: 'View',
              action: props => {
                this.viewUpcomingServiceHistory(props)
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('tracking'),
              text: 'View Detail',
              action: props => {
                this.$router.push({
                  path: `/user/service/request/${props.id}`,
                })
              },
            },
            {
              isIcon: true,
              iconHtml: this.$helpers.getIcons('dispute'),
              text: 'Dispute',
              action: props => {
                this.createUpcomingServiceDispute(props)
              },
            },
          ],
        },
      ],
    }
  },
  computed: {
    storeTimings() {
      if (!this.providerTimings) return null
      return this.providerTimings.find(x => x.day === this.currentDay)
    },
    currentDay() {
      return moment().format('ddd').toUpperCase()
    },
    currencyInfo() {
      if (!this.serviceHistoryProps.tripHistoryObject) return ''
      return this.serviceHistoryProps.tripHistoryObject.currency
    },
    canReschedule() {
      if (!this.serviceHistoryProps.tripHistoryObject) return false
      return this.serviceHistoryProps.tripHistoryObject.status === 'ASSIGNED' || this.serviceHistoryProps.tripHistoryObject.status === 'ACCEPTED' || this.serviceHistoryProps.tripHistoryObject.status === 'SEARCHING'
    },
    disputeObject() {
      return this.serviceHistoryProps.disputeObject && this.serviceHistoryProps.disputeObject.id
    },
    getServiceType() {
      let serviceType = ''
      if (this.serviceHistoryProps.tripHistoryObject && this.serviceHistoryProps.tripHistoryObject.service) {
        if (this.serviceHistoryProps.tripHistoryObject.service.service_category && this.serviceHistoryProps.tripHistoryObject.service.servicesub_category) {
          serviceType = `${this.serviceHistoryProps.tripHistoryObject.service.service_category.service_category_name} => ${this.serviceHistoryProps.tripHistoryObject.service.servicesub_category.service_subcategory_name}`
        } else if (this.serviceHistoryProps.tripHistoryObject.service.service_name) {
          serviceType = this.serviceHistoryProps.tripHistoryObject.service.service_name
        }
      }
      if (serviceType) {
        return serviceType
      }
      return 'NA'
    },
    cartDetails() {
      if (!this.serviceHistoryProps.tripHistoryObject) return []
      if (!this.serviceHistoryProps.tripHistoryObject.cart_details) return []
      return JSON.parse(this.serviceHistoryProps.tripHistoryObject.cart_details)
    },
  },
  methods: {
    moment,
    storeCurrentTimingsInfo() {
      if (!this.providerTimings) return null
      if (this.storeTimings) return this.storeTimings
      return this.providerTimings[0]
    },
    getProviderTimings() {
      const { getProviderTimings } = useUserUi()
      getProviderTimings(`?provider_id=${this.serviceHistoryProps.tripHistoryObject.provider.slug}`)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.providerTimings = data.responseData
            this.storeCurrentTimings = this.storeCurrentTimingsInfo()
          }
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    rescheduleRequest() {
      this.viewUpdateRequestModal = true
    },
    hideUpdateRequestModal() {
      this.viewUpdateRequestModal = false
    },
    updateRequestStatus() {
      const formData = new FormData()
      let date = ''
      let year = ''
      let time = ''
      let month = ''
      let day = ''
      let scheduleDate = ''
      if (this.$refs.calenderRef) {
        scheduleDate = new Date(`${this.$refs.calenderRef.$data.activeDate.toDateString()} ${this.$refs.calenderRef.$data.activeTimeSlot}`)
      }
      if (scheduleDate) {
        const splitDate = scheduleDate
        date = splitDate
        year = new Date(date).getFullYear()
        month = new Date(date).getMonth() + 1
        day = new Date(date).getDate()
        date = `${new Date(date).getMonth() + 1}/${new Date(date).getDate()}/${new Date(date).getFullYear()}`
        if (this.$refs.calenderRef) {
          time = this.$refs.calenderRef.$data.activeTimeSlot
        }
      }
      formData.append('id', this.serviceHistoryProps.tripHistoryObject.id)
      formData.append('request_id', this.serviceHistoryProps.tripHistoryObject.id)
      formData.append('user_id', this.serviceHistoryProps.tripHistoryObject.user_id)
      formData.append('provider_id', this.serviceHistoryProps.tripHistoryObject.provider_id)
      formData.append('status', 'SCHEDULED')
      formData.append('agent_id', this.serviceHistoryProps.tripHistoryObject.agent_id)
      formData.append('date', date)
      formData.append('time', time)
      formData.append('year', year)
      formData.append('day', day)
      formData.append('month', month)
      formData.append('comments', '')
      formData.append('admin_service', 'SERVICE')
      showLoader()
      const { updateProviderRequest } = useProviderUi()
      const requestUrl = 'user/services/reschedule'
      updateProviderRequest({
        query: requestUrl,
        data: formData,
      })
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, 'Request updated successfully')
            eventBus.$emit('provider-sync-app', {
              refreshDashboard: true,
              showNotification: `Reschedule updated for #${this.serviceHistoryProps.tripHistoryObject.booking_id}`,
              providerId: this.serviceHistoryProps.tripHistoryObject.provider_id,
              requestId: this.serviceHistoryProps.tripHistoryObject.id,
            })
            this.hideUpcomingServiceModal()
            this.hideUpdateRequestModal()
            this.$refs.upcomingServiceRef.loadItems()
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    submitApprovalRequest() {
      const formData = new FormData()
      formData.append('id', this.serviceHistoryProps.tripHistoryObject.id)
      formData.append('user_id', this.serviceHistoryProps.tripHistoryObject.user_id)
      formData.append('provider_id', this.serviceHistoryProps.tripHistoryObject.provider_id)
      formData.append('comments', this.serviceHistoryProps.acceptRequestMessag)
      formData.append('status', 'SCHEDULED')
      formData.append('approve', 1)

      const { userApproveService } = useUserUi()
      showLoader()
      userApproveService(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.$refs.upcomingServiceRef.loadItems()
            this.$refs.serviceHistoryRef.loadItems()
            this.serviceHistoryProps.upcomingServiceDetailVisible = false
            showSuccessNotification(this, 'Service approved')
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    viewServiceHistory(props) {
      this.serviceHistoryProps.currentServiceForDispute = props
      this.serviceHistoryProps.upcomingServiceDetailVisible = true
      this.getTripHistory()
    },
    hideDisputeModal() {
      this.serviceHistoryProps.viewDisputeModal = false
    },
    hideUpcomingServiceModal() {
      this.serviceHistoryProps.upcomingServiceDetailVisible = false
    },
    rescheduleService(status) {
      this.$swal({
        title: this.$t('Do you approve the request?'),
        text: '',
        iconHtml: this.$helpers.swalIcon('confirm.png'),
        showCancelButton: true,
        confirmButtonText: 'Yes',
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          showLoader()
          const { rescheduleService } = useUserUi()
          const formData = new FormData()
          formData.append('status', status)
          formData.append('request_id', this.serviceHistoryProps.tripHistoryObject.id)
          rescheduleService(formData)
            .then(({ data }) => {
              if (data.statusCode === '200') {
                eventBus.$emit('provider-sync-app', {
                  refreshDashboard: true,
                  providerId: this.serviceHistoryProps.tripHistoryObject.provider_id,
                })
                this.hideUpcomingServiceModal()
                this.hideUpdateRequestModal()
                this.$refs.upcomingServiceRef.loadItems()
                showSuccessNotification(this, 'Request rescheduled successfully')
              }
              hideLoader()
            })
            .catch(error => {
              showErrorNotification(this, error)
            })
        }
      })
    },
    cancelServiceRequest() {
      this.$swal({
        title: this.$t('You want to cancel request?'),
        iconHtml: this.$helpers.swalIcon('confirm.png'),
        showCancelButton: true,
        confirmButtonText: this.$t('Yes'),
        cancelButtonText: this.$t('No'),
        customClass: {
          confirmButton: 'btn btn-success',
          cancelButton: 'btn btn-danger ml-1',
          icon: 'border-0',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          showLoader()
          const { userCancelServiceRequest } = useUserUi()
          userCancelServiceRequest({
            id: this.serviceHistoryProps.currentServiceForDispute.id,
          })
            .then(({ data }) => {
              if (data.statusCode === '200') {
                this.hideUpcomingServiceModal()
                this.$refs.upcomingServiceRef.loadItems()
                showSuccessNotification(this, data.message)
              }
              hideLoader()
            })
            .catch(error => {
              showErrorNotification(this, error)
            })
        }
      })
    },
    createServiceDispute(props) {
      this.serviceHistoryProps.currentServiceForDispute = props
      this.serviceHistoryProps.viewDisputeModal = true
      this.getDisputeStatus()
    },
    viewUpcomingServiceHistory(props) {
      this.serviceHistoryProps.currentServiceForDispute = props
      this.serviceHistoryProps.upcomingServiceDetailVisible = true
      this.getTripHistory()
    },
    createUpcomingServiceDispute(props) {
      this.serviceHistoryProps.currentServiceForDispute = props
      this.serviceHistoryProps.viewDisputeModal = true
      this.getDisputeStatus()
    },
    getTripHistory() {
      showLoader()
      this.serviceHistoryProps.tripHistoryObject = {}
      const { userTripServiceHistory } = useUserUi()
      userTripServiceHistory(this.serviceHistoryProps.currentServiceForDispute.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.serviceHistoryProps.tripHistoryObject = data.responseData.service
            this.getProviderTimings()
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    getDisputeStatus() {
      showLoader()
      this.serviceHistoryProps.disputeObject = {}
      const { userDisputeStatus } = useUserUi()
      userDisputeStatus(this.serviceHistoryProps.currentServiceForDispute.id)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            this.serviceHistoryProps.disputeObject = data.responseData
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
    submitServiceDispute() {
      const formData = new FormData()
      formData.append('referral_email', '')
      formData.append('dispute_type', 'user')
      formData.append('dispute_name', this.serviceHistoryProps.currentDispute)
      formData.append('comments', this.serviceHistoryProps.currentDispute === 'Others' ? this.serviceHistoryProps.disputeReason : '')
      formData.append('id', this.serviceHistoryProps.currentServiceForDispute.id)
      formData.append('user_id', this.serviceHistoryProps.currentServiceForDispute.user_id)
      formData.append('provider_id', this.serviceHistoryProps.currentServiceForDispute.provider_id)

      const { createServiceDispute } = useUserUi()
      showLoader()
      createServiceDispute(formData)
        .then(({ data }) => {
          if (data.statusCode === '200') {
            showSuccessNotification(this, data.message)
          } else {
            showDangerNotification(this, data.message)
          }
          hideLoader()
        })
        .catch(error => {
          showErrorNotification(this, error)
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-good-table.scss';
@import '@core/scss/vue/libs/vue-select.scss';

.vs__dropdown-toggle {
  border: 1px solid #d8d6de;
}

#create-dispute-modal .modal-body {
  height: 220px;
}

#create-dispute-modal.height-auto .modal-body {
  height: auto;
}

#upcomingServiceModal .modal-dialog {
  max-width: 80%;
}
</style>
